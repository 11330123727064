/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { removeUnordered as t } from "../core/arrayUtils.js";
import { strict as r } from "../core/jsonMap.js";
import { JSONSupport as o } from "../core/JSONSupport.js";
import { unitFromRESTJSON as i, getVerticalUnitStringForSR as s } from "../core/unitUtils.js";
import n from "../core/Warning.js";
import { property as a } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { reader as l } from "../core/accessorSupport/decorators/reader.js";
import { subclass as h } from "../core/accessorSupport/decorators/subclass.js";
import { writer as c } from "../core/accessorSupport/decorators/writer.js";
var d;
const p = r()({
    orthometric: "gravity-related-height",
    gravity_related_height: "gravity-related-height",
    ellipsoidal: "ellipsoidal"
  }),
  u = p.jsonValues.slice();
t(u, "orthometric");
const g = r()({
  meter: "meters",
  foot: "feet",
  "us-foot": "us-feet",
  "clarke-foot": "clarke-feet",
  "clarke-yard": "clarke-yards",
  "clarke-link": "clarke-links",
  "sears-yard": "sears-yards",
  "sears-foot": "sears-feet",
  "sears-chain": "sears-chains",
  "benoit-1895-b-chain": "benoit-1895-b-chains",
  "indian-yard": "indian-yards",
  "indian-1937-yard": "indian-1937-yards",
  "gold-coast-foot": "gold-coast-feet",
  "sears-1922-truncated-chain": "sears-1922-truncated-chains",
  "50-kilometers": "50-kilometers",
  "150-kilometers": "150-kilometers"
});
let m = d = class extends o {
  constructor(e) {
    super(e), this.heightModel = "gravity-related-height", this.heightUnit = "meters", this.vertCRS = null;
  }
  writeHeightModel(e, t, r) {
    return p.write(e, t, r);
  }
  readHeightModel(e, t, r) {
    const o = p.read(e);
    return o || (r?.messages && r.messages.push(f(e, {
      context: r
    })), null);
  }
  readHeightUnit(e, t, r) {
    const o = g.read(e);
    return o || (r?.messages && r.messages.push(y(e, {
      context: r
    })), null);
  }
  readHeightUnitService(e, t, r) {
    const o = i(e) || g.read(e);
    return o || (r?.messages && r.messages.push(y(e, {
      context: r
    })), null);
  }
  readVertCRS(e, t) {
    return t.vertCRS || t.ellipsoid || t.geoid;
  }
  clone() {
    return new d({
      heightModel: this.heightModel,
      heightUnit: this.heightUnit,
      vertCRS: this.vertCRS
    });
  }
  equals(e) {
    return !!e && (this === e || this.heightModel === e.heightModel && this.heightUnit === e.heightUnit && this.vertCRS === e.vertCRS);
  }
  static deriveUnitFromSR(e, t) {
    const r = s(t);
    return new d({
      heightModel: e.heightModel,
      heightUnit: r ?? void 0,
      vertCRS: e.vertCRS
    });
  }
  write(e, t) {
    return t = {
      origin: "web-scene",
      ...t
    }, super.write(e, t);
  }
  static fromJSON(e) {
    if (!e) return null;
    const t = new d();
    return t.read(e, {
      origin: "web-scene"
    }), t;
  }
};
function y(e, t) {
  return new n("height-unit:unsupported", `Height unit of value '${e}' is not supported`, t);
}
function f(e, t) {
  return new n("height-model:unsupported", `Height model of value '${e}' is not supported`, t);
}
e([a({
  type: p.apiValues,
  constructOnly: !0,
  json: {
    origins: {
      "web-scene": {
        type: u,
        default: "ellipsoidal"
      }
    }
  }
})], m.prototype, "heightModel", void 0), e([c("web-scene", "heightModel")], m.prototype, "writeHeightModel", null), e([l(["web-scene", "service"], "heightModel")], m.prototype, "readHeightModel", null), e([a({
  type: g.apiValues,
  constructOnly: !0,
  json: {
    origins: {
      "web-scene": {
        type: g.jsonValues,
        write: g.write
      }
    }
  }
})], m.prototype, "heightUnit", void 0), e([l("web-scene", "heightUnit")], m.prototype, "readHeightUnit", null), e([l("service", "heightUnit")], m.prototype, "readHeightUnitService", null), e([a({
  type: String,
  constructOnly: !0,
  json: {
    origins: {
      "web-scene": {
        write: !0
      }
    }
  }
})], m.prototype, "vertCRS", void 0), e([l("service", "vertCRS", ["vertCRS", "ellipsoid", "geoid"])], m.prototype, "readVertCRS", null), m = d = e([h("esri.geometry.HeightModelInfo")], m);
const v = m;
export { v as default };